// error-dialog.component.ts
import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  template: `
    <div class="error-dialog-container">
    <h1 style="text-align: center">Whoops</h1>
    <p style="padding: 5px">{{ data.message }}</p>
      <div class="button-container">
      <button mat-raised-button color="primary" style="align-content: center; justify-content: center;" (click)="closeDialog()">Close</button>
        </div>
    </div>
  `,
  styles: [`
    .error-dialog-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .button-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `]
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ErrorDialogComponent>) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
